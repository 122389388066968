import React, { useState } from "react";
import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";
import { SelectedDatasetsContainer } from "./components/selected-datasets-container";
import { Button } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DatasetsSelectorModal } from "../../space-modal/dataset-selector-modal/datasets-selector-modal";
import "./styles.scss";
import { DeleteDatasetsGroupModal } from "../../space-modal/delete-datasets-group-modal/delete-datasets-group-modal";
import { faFilePlus } from "@fortawesome/pro-light-svg-icons";

export interface DatasetsSelectorProps {
    className?: string;
    hasTitle?: boolean;
    isLoading?: boolean;
    isGroupedDatasets: boolean;
    selectedDatasetGroups?: StoredDatasetGroup[];
    selectedDatasets?: StoredDataset[];
    onSelectDatasets: (datasets: StoredDataset[], groupNames: string[]) => void;
    onHandleDismissGroup?: (groupName: string) => void;
    onHandleDismissAllGroups?: () => void;
    onHandleDatasetReorder?: (updatedDatasets: StoredDataset[]) => void;
    onHandleGroupReorder?: (updatedGroups: StoredDatasetGroup[]) => void;
    testId?: string;
}

export function DatasetsSelector({
    className,
    hasTitle,
    isLoading,
    isGroupedDatasets,
    selectedDatasetGroups,
    selectedDatasets,
    onSelectDatasets,
    onHandleDismissGroup,
    onHandleDismissAllGroups,
    onHandleDatasetReorder,
    onHandleGroupReorder,
    testId
}: DatasetsSelectorProps) {
    const cnParts = ["datasets-selector"];

    if (isGroupedDatasets) {
        cnParts.push("grouped-datasets");
    }

    const [isDataSelectorModalOpen, setIsDataSelectorModalOpen] =
        useState(false);
    const [isDeleteAllDatasetsModalOpen, setIsDeleteAllDatasetsModalOpen] =
        useState(false);
    const [datasetGroups, setDatasetGroups] = useState<StoredDatasetGroup[]>(
        selectedDatasetGroups || []
    );

    const handleDismissDataset = (fileName: string, groupName?: string) => {
        if (isGroupedDatasets && groupName) {
            const updatedGroups = datasetGroups.map((group) => {
                if (group.name === groupName) {
                    return {
                        ...group,
                        group: group.group.filter(
                            (dataset) => dataset.fileName !== fileName
                        )
                    };
                }
                return group;
            });

            setDatasetGroups(updatedGroups);
            onHandleGroupReorder?.(updatedGroups);
        } else {
            const updatedDatasets = selectedDatasets?.filter(
                (dataset) => dataset.fileName !== fileName
            );

            onHandleDatasetReorder?.(updatedDatasets || []);
        }
    };

    const handleDismissGroup = (groupName: string) => {
        const updatedGroups = datasetGroups.filter(
            (group) => group.name !== groupName
        );
        setDatasetGroups(updatedGroups);
        onHandleDismissGroup?.(groupName);
    };

    const handleDismissDatasetGroups = () => {
        setDatasetGroups([]);
        onHandleDismissAllGroups?.();
        setIsDeleteAllDatasetsModalOpen(!isDeleteAllDatasetsModalOpen);
    };

    const handleDatasetReorder = (updatedDatasets: StoredDataset[]) => {
        onHandleDatasetReorder?.(updatedDatasets);
    };

    const handleGroupReorder = (updatedGroups: StoredDatasetGroup[]) => {
        setDatasetGroups(updatedGroups);
        onHandleGroupReorder?.(updatedGroups);
    };

    const handleCreateGroup = (newGroupName: string) => {
        const newGroup: StoredDatasetGroup = {
            name: newGroupName,
            group: []
        };
        const updatedGroups = [...datasetGroups, newGroup];
        setDatasetGroups(updatedGroups);
        onHandleGroupReorder?.(updatedGroups);
    };

    const handleSelectDatasets = (
        datasets: StoredDataset[],
        groupNames: string[]
    ) => {
        const updatedGroups = datasetGroups.map((group) => {
            if (groupNames.includes(group.name)) {
                const newDatasetsToAdd = datasets.filter(
                    (dataset) =>
                        !group.group.some(
                            (d) => d.fileName === dataset.fileName
                        )
                );
                if (newDatasetsToAdd.length > 0) {
                    return {
                        ...group,
                        group: [...group.group, ...newDatasetsToAdd]
                    };
                }
            }
            return group;
        });

        setDatasetGroups(updatedGroups as StoredDatasetGroup[]);
        onSelectDatasets(datasets, groupNames);
    };

    if (className) {
        cnParts.push(className);
    }

    const totalDatasetsInGroups =
        datasetGroups.reduce((total, group) => total + group.group.length, 0) ||
        0;

    const DatasetsTitle = isGroupedDatasets
        ? `${totalDatasetsInGroups} Datasets Added`
        : `${selectedDatasets?.length || 0} Datasets Added`;
    const addDatasetsButtonIcon = faFilePlus as IconProp;

    const selectedDatasetsTestId = testId ? `${testId}-datasets` : undefined;
    const cn = cnParts.join(" ");

    return (
        <>
            <div className={cn}>
                {hasTitle && <div className="title">{DatasetsTitle}</div>}
                <div className="dataset-selector-action-buttons">
                    {isGroupedDatasets && (
                        <Button
                            className="delete-all-dataset-groups"
                            text="Delete All Groups"
                            color="danger"
                            isDisabled={datasetGroups.length === 0}
                            onClick={() =>
                                setIsDeleteAllDatasetsModalOpen(
                                    !isDeleteAllDatasetsModalOpen
                                )
                            }
                        />
                    )}
                    {isDeleteAllDatasetsModalOpen && (
                        <DeleteDatasetsGroupModal
                            className="delete-grouped-datasets-modal"
                            isDeletingAllGroups
                            isOpen={isDeleteAllDatasetsModalOpen}
                            totalGroups={datasetGroups.length}
                            onDeleteGroups={() => handleDismissDatasetGroups()}
                            onRequestClose={() =>
                                setIsDeleteAllDatasetsModalOpen(false)
                            }
                        />
                    )}
                    <Button
                        className="add-dataset-button"
                        text={"Add Dataset"}
                        color="primary"
                        icon={<FontAwesomeIcon icon={addDatasetsButtonIcon} />}
                        isIconAfterText
                        onClick={() =>
                            setIsDataSelectorModalOpen(!isDataSelectorModalOpen)
                        }
                    />
                </div>
            </div>
            <DatasetsSelectorModal
                className="datasets-selector-modal"
                isOpen={isDataSelectorModalOpen}
                isGroupedDatasets={isGroupedDatasets}
                selectedDatasets={selectedDatasets || []}
                selectedDatasetGroups={datasetGroups}
                onClose={() =>
                    setIsDataSelectorModalOpen(!isDataSelectorModalOpen)
                }
                onSelectDatasets={handleSelectDatasets}
                onCreateGroup={handleCreateGroup}
            />
            <SelectedDatasetsContainer
                selectedDatasets={selectedDatasets || []}
                selectedDatasetGroups={datasetGroups}
                isGroupedDatasets={isGroupedDatasets}
                handleDismissGroup={handleDismissGroup}
                handleDismissDataset={handleDismissDataset}
                handleDatasetReorder={handleDatasetReorder}
                handleGroupReorder={handleGroupReorder}
                testId={selectedDatasetsTestId}
                isLoading={isLoading}
            />
        </>
    );
}
