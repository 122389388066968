import React, {
    useState,
    useRef,
    useEffect,
    isValidElement,
    cloneElement,
    HTMLAttributes,
    ReactElement
} from "react";
import "./styles.scss";

export interface SpaceMenuProps<P extends HTMLAttributes<HTMLElement>> {
    className?: string;
    baseElement: ReactElement<P>;
    children: React.ReactNode;
    closeOnOutsideClick?: boolean;
    isOpen?: boolean;
    isScrollable?: boolean;
    isMenuOntop?: boolean;
    testId?: string;
    onToggle?: (isOpen: boolean) => void;
}

export function SpaceMenu<P extends HTMLAttributes<HTMLElement>>({
    className,
    baseElement,
    children,
    closeOnOutsideClick = true,
    isOpen,
    isScrollable = false,
    isMenuOntop = false,
    testId,
    onToggle
}: SpaceMenuProps<P>): JSX.Element | null {
    const cnParts = ["space-menu"];
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(isOpen ?? false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen !== undefined) {
            setIsMenuOpen(isOpen);
        }
    }, [isOpen]);

    useEffect(() => {
        let handleClickOutside: (event: MouseEvent | TouchEvent) => void;

        if (closeOnOutsideClick && isMenuOpen) {
            handleClickOutside = (event: MouseEvent | TouchEvent) => {
                if (
                    menuRef.current &&
                    !menuRef.current.contains(event.target as Node)
                ) {
                    setIsMenuOpen(false);
                    if (onToggle) {
                        onToggle(false);
                    }
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        }

        return () => {
            if (handleClickOutside) {
                document.removeEventListener("mousedown", handleClickOutside);
                document.removeEventListener("touchstart", handleClickOutside);
            }
        };
    }, [isMenuOpen, closeOnOutsideClick, onToggle]);

    const handleToggle = () => {
        setIsMenuOpen((prevOpen) => {
            const newOpenState = !prevOpen;
            if (onToggle) {
                onToggle(newOpenState);
            }
            return newOpenState;
        });
    };

    if (className) {
        cnParts.push(className);
    }
    if (isMenuOpen) {
        cnParts.push("open");
    }
    if (isMenuOntop) {
        cnParts.push("menu-on-top");
    }

    const cn = cnParts.join(" ");

    if (!isValidElement(baseElement)) {
        console.error("baseElement must be a valid React element.");
        return null;
    }

    const baseElementWithProps = cloneElement<P>(baseElement, {
        onClick: handleToggle,
        onKeyDown: (event: React.KeyboardEvent) => {
            if (event.key === "Enter" || event.key === " ") {
                handleToggle();
            }
        },
        tabIndex: 0,
        role: "button"
    } as unknown as Partial<P>);

    return (
        <div className={cn} ref={menuRef} data-testid={testId}>
            {baseElementWithProps}
            {isMenuOpen && (
                <div
                    className={`menu-content ${isScrollable ? "scrollable" : ""}`}
                >
                    {children}
                </div>
            )}
        </div>
    );
}
