import React from "react";
import { CyDataTypes } from "../../../../interfaces/analysis-types";

interface DataTypeSectionProps {
    dataType: CyDataTypes;
    onChange: (value: CyDataTypes) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function DataTypeSection({
    dataType,
    onChange,
    isDisabled,
    testId
}: DataTypeSectionProps): JSX.Element {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as CyDataTypes);
    };

    return (
        <div
            className="parameter-section three-items data-type"
            data-testid={testId}
        >
            <h4>Data Type</h4>
            <div className="parameter-radio-buttons data-type-radio-buttons">
                <label className="data-type-freq-sweep">
                    <input
                        type="radio"
                        name="data-type"
                        value="frequencySweep"
                        checked={dataType === "frequencySweep"}
                        onChange={handleChange}
                        disabled={isDisabled}
                        data-testid={`${testId}-freq-sweep`}
                    />
                    Frequency Sweep
                </label>
                <label className="data-type-temp-freq-sweep">
                    <input
                        type="radio"
                        name="data-type"
                        value="tempFreqSweep"
                        checked={dataType === "tempFreqSweep"}
                        onChange={handleChange}
                        disabled={isDisabled}
                        data-testid={`${testId}-temp-freq-sweep`}
                    />
                    Temp/Freq Sweep
                </label>
            </div>
        </div>
    );
}
