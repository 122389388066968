import React from "react";
import { Checkbox } from "@cpchem/covalence-ui";

interface YieldStressTermSectionProps {
    isUsingYieldStressTerm: boolean;
    onChange: (checked: boolean) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function YieldStressTermSection({
    isUsingYieldStressTerm,
    onChange,
    isDisabled,
    testId
}: YieldStressTermSectionProps): JSX.Element {
    return (
        <div
            className="parameter-section two-items yield-stress"
            data-testid={testId}
        >
            <h4>Yield Stress Term</h4>
            <div className="yield-stress-checkbox">
                <Checkbox
                    label=""
                    isChecked={isUsingYieldStressTerm}
                    onChange={onChange}
                    isDisabled={isDisabled}
                    testId={`${testId}-checkbox`}
                />
            </div>
        </div>
    );
}
