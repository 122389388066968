import React from "react";
import { TextField } from "@cpchem/covalence-ui";

interface ReportInformationSectionProps {
    submitter: string;
    description: string;
    onSubmitterChange: (value: string) => void;
    onDescriptionChange: (value: string) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function ReportInformationSection({
    submitter,
    description,
    onSubmitterChange,
    onDescriptionChange,
    isDisabled,
    testId
}: ReportInformationSectionProps): JSX.Element {
    return (
        <div className="report-information-section" data-testid={testId}>
            <h4 className="form-item-title">Report Information</h4>
            <TextField
                label="Submitter"
                value={submitter}
                onChange={onSubmitterChange}
                isDisabled={isDisabled}
                isRequired
                placeholder="Enter the submitters name"
                testId={`${testId}-submitter`}
            />
            <TextField
                label="Description"
                value={description}
                onChange={onDescriptionChange}
                isDisabled={isDisabled}
                isRequired
                isMultiline
                placeholder="Enter a description for this report"
                testId={`${testId}-description`}
            />
        </div>
    );
}
