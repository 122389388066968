import React, { useEffect, useState } from "react";
import { Checkbox } from "@cpchem/covalence-ui";
import { SummaryAttributes } from "../../../../interfaces/summary-attributes";
import { fetchCYParametersData } from "../utils/fetch-cy-parameters";

interface TableOfCyParametersSectionProps {
    tableOfCyParameters: SummaryAttributes[];
    onTableOfCyParametersChange: (value: SummaryAttributes[]) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function TableOfCyParametersSection({
    tableOfCyParameters,
    onTableOfCyParametersChange,
    isDisabled,
    testId
}: TableOfCyParametersSectionProps): JSX.Element {
    const [cyParametersOptions, setCyParametersOptions] = useState<
        {
            label: string;
            value: SummaryAttributes;
        }[]
    >([]);

    useEffect(() => {
        const fetchData = async () => {
            const parameters = (await fetchCYParametersData()).map((param) => ({
                label: param.label,
                value: param.value as SummaryAttributes
            }));
            setCyParametersOptions(parameters);
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (
        parameter: SummaryAttributes,
        isChecked: boolean
    ) => {
        let updatedParameters: SummaryAttributes[];
        if (isChecked) {
            updatedParameters = [...tableOfCyParameters, parameter];
        } else {
            updatedParameters = tableOfCyParameters.filter(
                (param) => param !== parameter
            );
        }
        onTableOfCyParametersChange(updatedParameters);
    };

    const filteredOptions = cyParametersOptions.filter(
        (option) =>
            option.value !== "sample_name" && option.value !== "sample_filename"
    );

    return (
        <div className="cy-parameters-section" data-testid={testId}>
            <h4>CY Table Parameters</h4>
            <div className="cy-parameters-items">
                {filteredOptions.map((option) => (
                    <Checkbox
                        key={option.value}
                        label={option.label}
                        isChecked={tableOfCyParameters.includes(option.value)}
                        onChange={(checked) =>
                            handleCheckboxChange(option.value, checked)
                        }
                        isDisabled={isDisabled}
                        testId={`${testId}-checkbox-${option.value}`}
                    />
                ))}
            </div>
        </div>
    );
}
