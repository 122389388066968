import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGripDotsVertical, faX } from "@fortawesome/pro-solid-svg-icons";

export interface SelectedDatasetProps {
    className?: string;
    dataset: string;
    handleDismissDataset: (fileName: string) => void;
    handleDragStart: (
        event: React.DragEvent<HTMLLIElement>,
        fileName: string
    ) => void;
    handleDragOver: (event: React.DragEvent<HTMLLIElement>) => void;
    handleDragEnd: () => void;
    testId?: string;
}

export function SelectedDataset({
    className,
    dataset,
    handleDismissDataset,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    testId
}: SelectedDatasetProps) {
    const cnParts = ["selected-dataset"];
    const RemoveFileExtension = (fileName: string) =>
        fileName.split(".").slice(0, -1).join(".");

    if (className) {
        cnParts.push(className);
    }

    const selectedDatasetTestId = testId
        ? `${testId}-selected-dataset`
        : undefined;
    const selectedDatasetDragButtonTestId = testId
        ? `${testId}-drag`
        : undefined;
    const selectedDatasetDismissButtonTestId = testId
        ? `${testId}-dismiss`
        : undefined;

    const cn = cnParts.join(" ");

    return (
        <li
            className={cn}
            data-testid={`${dataset}-${selectedDatasetTestId}`}
            draggable
            onDragStart={(event) => {
                event.stopPropagation();
                handleDragStart(event, dataset);
            }}
            onDragOver={(event) => {
                event.stopPropagation();
                handleDragOver(event);
            }}
            onDragEnd={(event) => {
                event.stopPropagation();
                handleDragEnd();
            }}
        >
            <div className="selected-dataset-icon-and-name">
                <button
                    className="dataset-drag"
                    data-testid={selectedDatasetDragButtonTestId}
                    aria-label={`Drag ${dataset} to reorder`}
                >
                    <FontAwesomeIcon
                        icon={faGripDotsVertical as IconProp}
                        className="drag-icon"
                    />
                </button>

                <div className="dataset-name">
                    {RemoveFileExtension(dataset)}
                </div>
            </div>
            <button
                className="dataset-dismiss"
                onClick={() => handleDismissDataset(dataset)}
                data-testid={`${dataset}-${selectedDatasetDismissButtonTestId}`}
                aria-label={`Remove ${dataset} from selected datasets`}
            >
                <FontAwesomeIcon
                    icon={faX as IconProp}
                    className="dismiss-icon"
                />
            </button>
        </li>
    );
}
