type Color = {
    name: string;
    hexValue: string;
};

export type StyleAssignment = {
    color: string;
    symbol: string;
    dash: string;
};

const Colors: Color[] = [
    { name: "blue", hexValue: "#0000FF" },
    { name: "red", hexValue: "#FF0000" },
    { name: "green", hexValue: "#008000" },
    { name: "fuchsia", hexValue: "#FF00FF" },
    { name: "navy", hexValue: "#000080" },
    { name: "purple", hexValue: "#800080" },
    { name: "lime", hexValue: "#00FF00" },
    { name: "maroon", hexValue: "#800000" },
    { name: "aqua", hexValue: "#00FFFF" },
    { name: "olive", hexValue: "#808000" },
    { name: "black", hexValue: "#000000" }
];

export default Colors;

const getColorValue = (name: string): string => {
    return Colors.find((color) => color.name === name)?.hexValue ?? "";
};

export const StyleAssignments: StyleAssignment[] = [
    { color: getColorValue("blue"), symbol: "circle", dash: "solid" },
    { color: getColorValue("red"), symbol: "triangle-up", dash: "dash" },
    {
        color: getColorValue("green"),
        symbol: "square",
        dash: "longdashdot"
    },
    {
        color: getColorValue("fuchsia"),
        symbol: "triangle-down",
        dash: "longdashdot"
    },
    {
        color: getColorValue("navy"),
        symbol: "diamond",
        dash: "longdash"
    },
    {
        color: getColorValue("purple"),
        symbol: "circle-open",
        dash: "dashdot"
    },
    {
        color: getColorValue("lime"),
        symbol: "triangle-up-open",
        dash: "solid"
    },
    {
        color: getColorValue("maroon"),
        symbol: "square-open",
        dash: "dash"
    },
    {
        color: getColorValue("aqua"),
        symbol: "triangle-down-open",
        dash: "longdashdot"
    },
    {
        color: getColorValue("olive"),
        symbol: "diamond-open",
        dash: "longdashdot"
    },
    {
        color: getColorValue("blue"),
        symbol: "circle-open",
        dash: "longdash"
    },
    {
        color: getColorValue("red"),
        symbol: "triangle-up-open",
        dash: "dashdot"
    },
    { color: getColorValue("green"), symbol: "square-open", dash: "solid" },
    {
        color: getColorValue("fuchsia"),
        symbol: "triangle-down-open",
        dash: "dash"
    },
    {
        color: getColorValue("navy"),
        symbol: "diamond-open",
        dash: "longdashdot"
    },
    {
        color: getColorValue("purple"),
        symbol: "circle-open",
        dash: "longdashdot"
    },
    {
        color: getColorValue("lime"),
        symbol: "triangle-up-open",
        dash: "longdash"
    },
    {
        color: getColorValue("maroon"),
        symbol: "square-open",
        dash: "dashdot"
    },
    {
        color: getColorValue("aqua"),
        symbol: "triangle-down-open",
        dash: "solid"
    },
    { color: getColorValue("olive"), symbol: "diamond-open", dash: "dash" },
    { color: getColorValue("black"), symbol: "circle-open", dash: "solid" }
];
