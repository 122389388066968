import React from "react";
import { TextField } from "@cpchem/covalence-ui";
import { handleNumericChange } from "../../../../utilities/report-analysis/handleNumericChange";

interface ReferenceTempSectionProps {
    referenceTemp: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function ReferenceTempSection({
    referenceTemp,
    onChange,
    isDisabled,
    testId
}: ReferenceTempSectionProps): JSX.Element {
    const handleChange = (value: string) => {
        handleNumericChange(value, onChange);
    };
    return (
        <div
            className="parameter-section two-items reference-temp"
            data-testid={testId}
        >
            <h4>Reference Temperature (°C)</h4>
            <div className="reference-temp-text-field parameters-text-field">
                <TextField
                    label="Reference Temperature"
                    isLabelHidden
                    value={referenceTemp || ""}
                    onChange={handleChange}
                    isDisabled={isDisabled}
                    testId={`${testId}-text-field`}
                />
            </div>
        </div>
    );
}
