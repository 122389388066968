import React from "react";
import { TextField } from "@cpchem/covalence-ui";
import { handleNumericChange } from "../../../../utilities/report-analysis/handleNumericChange";

interface VogelTempSectionProps {
    vogelTemp: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function VogelTempSection({
    vogelTemp,
    onChange,
    isDisabled,
    testId
}: VogelTempSectionProps): JSX.Element {
    const handleChange = (value: string) => {
        handleNumericChange(value, onChange);
    };

    return (
        <div
            className="parameter-section two-items vogel-temp"
            data-testid={testId}
        >
            <h4>Vogel Temperature (K)</h4>
            <div className="vogel-temp-text-field parameters-text-field">
                <TextField
                    label="Vogel Temperature"
                    isLabelHidden
                    value={vogelTemp || ""}
                    onChange={handleChange}
                    isDisabled={isDisabled}
                    testId={`${testId}-text-field`}
                />
            </div>
        </div>
    );
}
