import { CyRheologyParameters } from "../../../interfaces/analysis-types";

const validDataTypes = ["frequencySweep", "tempFreqSweep"];
const validResinTypes = ["PE", "PP", "PS", "Other"];

export function ValidateCyRheologyParameters(
    params: CyRheologyParameters
): boolean {
    const isValidParameters =
        ValidateDatasets(params.selectedDatasets.length) &&
        ValidateResinType(params.resinType) &&
        ValidateComparison(
            params.isComparingDatasets,
            params.selectedCompareDataset
        ) &&
        ValidateDataType(params.dataType) &&
        ValidateNValue(params.fixN, params.nValue) &&
        ValidateVogelTemp(params.vogelTemp) &&
        ValidateReferenceTemp(params.referenceTemp);

    return isValidParameters;
}

export function ValidateDatasets(datasets: number): boolean {
    if (datasets === 0) {
        return false;
    }
    return true;
}

export function ValidateDataType(dataType: string): boolean {
    if (!validDataTypes.includes(dataType)) {
        return false;
    }
    return true;
}

export function ValidateNValue(fixN: boolean, nValue: string | null): boolean {
    if (fixN) {
        if (
            nValue === null ||
            (typeof nValue === "string" && nValue.trim() !== "")
        ) {
            if (Number.isNaN(parseFloat(nValue as string))) {
                return false;
            }
            return true;
        }
        return false;
    }
    return true;
}

export function ValidateVogelTemp(vogelTemp: string | null): boolean {
    if (Number.isNaN(parseFloat(vogelTemp as string))) {
        return false;
    }
    return true;
}

export function ValidateReferenceTemp(referenceTemp: string | null): boolean {
    if (Number.isNaN(parseFloat(referenceTemp as string))) {
        return false;
    }
    return true;
}

export function ValidateComparison(
    isComparingDatasets: boolean,
    selectedCompareDataset: string | null
): boolean {
    if (isComparingDatasets && !selectedCompareDataset) {
        return false;
    }
    return true;
}

export function ValidateResinType(resinType: string): boolean {
    if (!validResinTypes.includes(resinType)) {
        return false;
    }
    return true;
}
