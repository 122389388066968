import React, { useCallback } from "react";
import { Button, Checkbox, DataGridRow, Tooltip } from "@cpchem/covalence-ui";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDatasetManagement } from "../../../../stores/datasets/datasets-context";
import { StoredDataset } from "@services/space/datasets/models";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./data-grid-row-styles.scss";
import { formatExtensionToName } from "../../../../stores/datasets/datasets-actions";

export const DatasetFileSelector = ({
    fileName,
    fileType,
    isSelected,
    onSelectFile,
    testId
}: {
    fileName: string;
    fileType: string;
    isSelected: boolean;
    onSelectFile: (fileName: string, isSelected: boolean) => void;
    testId?: string;
}): JSX.Element => {
    const handleChange = useCallback(() => {
        onSelectFile(fileName, !isSelected);
    }, [fileName, isSelected, onSelectFile]);

    const checkboxTestId = testId
        ? `${testId}-${fileName}-checkbox`
        : undefined;

    return (
        <>
            {fileType ? (
                <Checkbox
                    isChecked={isSelected}
                    label=""
                    onChange={handleChange}
                    testId={checkboxTestId}
                />
            ) : (
                <Tooltip
                    content={
                        "Please select a data format before selecting a dataset."
                    }
                    side="topRight"
                    enterDelay="long"
                >
                    <Checkbox
                        isChecked={isSelected}
                        label=""
                        onChange={handleChange}
                        testId={checkboxTestId}
                    />
                </Tooltip>
            )}
        </>
    );
};

export const DatasetFormat = ({
    fileName,
    initialFileType,
    testId
}: {
    fileName: string;
    initialFileType: string;
    testId?: string;
}): JSX.Element => {
    const { state } = useDatasetManagement();
    const formatText =
        state.datasetFormats?.find(
            (format) => format.extension === initialFileType
        )?.title || formatExtensionToName[initialFileType];

    const selectorButtonTestId = testId
        ? `${testId}-${fileName}-dataset-format`
        : undefined;

    return <span data-testid={selectorButtonTestId}>{formatText}</span>;
};

export const DatasetsFileRemoveButton = ({
    fileName,
    onRemoveFile,
    testId
}: {
    fileName: string;
    onRemoveFile?: (fileName: string) => void;
    testId?: string;
}): JSX.Element => {
    const removeButtonTestId = testId
        ? `${testId}-${fileName}-remove-file`
        : undefined;
    return (
        <Tooltip content="Remove dataset" side="top" enterDelay="long">
            <Button
                className="remove-data-grid-row"
                color="red"
                onClick={() => onRemoveFile && onRemoveFile(fileName)}
                icon={<FontAwesomeIcon icon={faTrash as IconProp} />}
                size="large"
                variant="text"
                testId={removeButtonTestId}
            />
        </Tooltip>
    );
};

export const DatasetsFileNameButton = ({
    fileName,
    onDatasetFileNameClick,
    isDatasetsPage,
    testId
}: {
    fileName: string;
    onDatasetFileNameClick?: (fileName: string) => void;
    isDatasetsPage: boolean;
    testId?: string;
}): JSX.Element => {
    const datasetFileNameButton = testId
        ? `${testId}-dataset-${fileName}-button`
        : undefined;
    const datasetFileName = testId
        ? `${testId}-dataset-${fileName}-text`
        : undefined;
    return (
        <>
            {isDatasetsPage ? (
                <Button
                    className="dataset-file-name-button"
                    size="large"
                    text={fileName}
                    variant="text"
                    onClick={() =>
                        onDatasetFileNameClick &&
                        onDatasetFileNameClick(fileName)
                    }
                    testId={datasetFileNameButton}
                />
            ) : (
                <span
                    className="dataset-file-name"
                    data-testid={datasetFileName}
                >
                    {fileName}
                </span>
            )}
        </>
    );
};

export function RowRendererFull(
    row: DataGridRow,
    HandleSelectFile: (fileName: string, isSelected: boolean) => void,
    selectedFiles: string[],
    isDatasetsPage: boolean,
    onRemoveFile?: (fileName: string) => void,
    onDatasetFileNameClick?: (fileName: string) => void,
    testId?: string
): JSX.Element {
    const datasetRow = row.values as StoredDataset;
    const isSelected = selectedFiles.includes(datasetRow.fileName);

    const sampleName = datasetRow.metadata
        ?.flatMap((meta) => meta.attributes)
        ?.find((attr) => attr.identifier === "sample_name")?.value;

    return (
        <tr key={datasetRow.fileName}>
            <td className="file-selector-cell f2">
                <DatasetFileSelector
                    fileName={datasetRow.fileName}
                    fileType={datasetRow.fileType}
                    isSelected={isSelected}
                    onSelectFile={HandleSelectFile}
                    testId={testId}
                />
            </td>
            <td className="file-name-cell f1">
                <DatasetsFileNameButton
                    fileName={datasetRow.fileName}
                    onDatasetFileNameClick={onDatasetFileNameClick}
                    isDatasetsPage={isDatasetsPage}
                    testId={testId}
                />
            </td>
            <td className="sample-name-cell">
                {sampleName ? (
                    <span key={`${datasetRow.fileName}-sample-name`}>
                        {sampleName}
                    </span>
                ) : (
                    <span>N/A</span>
                )}
            </td>
            <td className="file-format-selector-cell f2">
                <DatasetFormat
                    fileName={datasetRow.fileName}
                    initialFileType={datasetRow.fileType}
                    testId={testId}
                />
            </td>
            <td className="file-size-cell f1">
                <span>{datasetRow.fileSize}</span>
            </td>
            {isDatasetsPage && (
                <td className="file-remover-cell f1">
                    <DatasetsFileRemoveButton
                        fileName={datasetRow.fileName}
                        onRemoveFile={onRemoveFile}
                        testId={testId}
                    />
                </td>
            )}
        </tr>
    );
}
