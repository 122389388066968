import React, { useState, useEffect } from "react";
import { Button, Modal, ModalContent, TextField } from "@cpchem/covalence-ui";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface EditDatasetsGroupModalProps {
    className?: string;
    isOpen: boolean;
    groupName: string;
    existingGroupNames: string[];
    onDeleteGroup: () => void;
    onGroupNameChange: (newGroupName: string) => void;
    onRequestClose: () => void;
    testId?: string;
}

export function EditDatasetsGroupModal({
    className,
    isOpen,
    groupName,
    existingGroupNames,
    onDeleteGroup,
    onGroupNameChange,
    onRequestClose,
    testId
}: EditDatasetsGroupModalProps): JSX.Element {
    const [newGroupName, setNewGroupName] = useState(groupName);
    const [isDuplicateName, setIsDuplicateName] = useState(false);

    useEffect(() => {
        setNewGroupName(groupName);
    }, [groupName]);

    useEffect(() => {
        setIsDuplicateName(
            existingGroupNames.includes(newGroupName) &&
                newGroupName !== groupName
        );
    }, [newGroupName, existingGroupNames, groupName]);

    const cnParts = ["edit-grouped-datasets-modal", "space-modal"];

    if (className) {
        cnParts.push(className);
    }

    function handleDeleteGroup() {
        onDeleteGroup();
        onRequestClose();
    }

    function handleGroupNameChange() {
        if (!isDuplicateName) {
            onGroupNameChange(newGroupName);
            setNewGroupName("");
        }
    }

    const handleInputChange = (value: string) => {
        /**
         * Only allows alphanumeric characters and spaces
         */
        const regex = /^[a-zA-Z0-9\s]*$/;
        if (regex.test(value)) {
            setNewGroupName(value);
        }
    };

    const removeButtonTestId = testId ? `${testId}-delete` : undefined;
    const saveNameButtonTestId = testId ? `${testId}-save-name` : undefined;
    const closeButtonTestId = testId ? `${testId}-close` : undefined;
    const textFieldTestId = testId ? `${testId}-group-name` : undefined;

    const cn = cnParts.join(" ");
    return (
        <Modal
            className={cn}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            showCloseButton={false}
            testId={testId}
        >
            <div className="modal-header">
                <div className="modal-header-title">
                    Edit Group &quot;{groupName}&quot;
                </div>
                <Button
                    className="modal-close-button"
                    onClick={onRequestClose}
                    variant="text"
                    icon={<FontAwesomeIcon icon={faX as IconProp} />}
                    size="large"
                    testId={closeButtonTestId}
                />
            </div>
            <ModalContent>
                <div className="remove-datasets-modal-content">
                    <TextField
                        label="Group Name"
                        placeholder="A Great New Name"
                        value={newGroupName}
                        onChange={handleInputChange}
                        testId={textFieldTestId}
                        errorMessage={
                            isDuplicateName
                                ? "Group name already exists"
                                : undefined
                        }
                        description="Only alphanumeric characters and spaces are allowed"
                    />
                </div>

                <div className="modal-action-buttons edit-datasets-group-action-buttons">
                    <Button
                        className="delete-group-button"
                        color="danger"
                        variant="solid"
                        onClick={handleDeleteGroup}
                        testId={removeButtonTestId}
                        text="Delete Group"
                    />
                    <div className="modal-name-edit-buttons">
                        <Button
                            color="primary"
                            variant="outline"
                            text="Back"
                            onClick={onRequestClose}
                        />
                        <Button
                            color="primary"
                            variant="solid"
                            text="Save"
                            onClick={handleGroupNameChange}
                            testId={saveNameButtonTestId}
                            isDisabled={isDuplicateName}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );
}
