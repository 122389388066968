import React, { useEffect, useState } from "react";
import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";
import { SelectedDataset } from "./selected-dataset";
import { SelectedDatasetGroup } from "./selected-dataset-group";
import { EditDatasetsGroupModal } from "../../../space-modal/edit-datasets-group-modal/edit-datasets-group-modal";
import { DeleteDatasetsGroupModal } from "../../../space-modal/delete-datasets-group-modal/delete-datasets-group-modal";

export interface SelectedDatasetsContainerProps {
    selectedDatasets: StoredDataset[];
    selectedDatasetGroups: StoredDatasetGroup[];
    isLoading?: boolean;
    isGroupedDatasets: boolean;
    handleDismissGroup?: (groupName: string) => void;
    handleDismissDataset: (fileName: string) => void;
    handleDatasetReorder: (updatedDatasets: StoredDataset[]) => void;
    handleGroupReorder: (updatedGroups: StoredDatasetGroup[]) => void;
    testId?: string;
}

export function SelectedDatasetsContainer({
    selectedDatasets,
    selectedDatasetGroups,
    isLoading,
    isGroupedDatasets,
    handleDismissGroup,
    handleDismissDataset,
    handleDatasetReorder,
    handleGroupReorder,
    testId
}: SelectedDatasetsContainerProps) {
    const cnParts = ["selected-datasets-container-wrapper"];

    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(
        null
    );
    const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
    const [isDeleteDatasetGroupModalOpen, setIsDeleteDatasetGroupModalOpen] =
        useState(false);
    const [currentGroupName, setCurrentGroupName] = useState<string>("");
    const [localGroupsOrder, setLocalGroupsOrder] = useState<
        StoredDatasetGroup[]
    >(selectedDatasetGroups);
    const [localDatasetsOrder, setLocalDatasetsOrder] =
        useState<StoredDataset[]>(selectedDatasets);

    useEffect(() => {
        setLocalGroupsOrder(selectedDatasetGroups);
    }, [selectedDatasetGroups]);

    useEffect(() => {
        setLocalDatasetsOrder(selectedDatasets);
    }, [selectedDatasets]);

    if (isGroupedDatasets) {
        cnParts.push("grouped-datasets-wrapper");
    }

    const handleDragStart = (
        event: React.DragEvent<HTMLElement>,
        index: number
    ) => {
        setDraggedItemIndex(index);
        event.dataTransfer.effectAllowed = "move";
        document.body.classList.add("dragging");
    };

    const handleDragOver = (
        event: React.DragEvent<HTMLElement>,
        index: number
    ) => {
        event.preventDefault();
        if (draggedItemIndex !== null && draggedItemIndex !== index) {
            if (isGroupedDatasets) {
                const updatedGroups = [...localGroupsOrder];
                const [draggedItem] = updatedGroups.splice(draggedItemIndex, 1);
                updatedGroups.splice(index, 0, draggedItem);
                setLocalGroupsOrder(updatedGroups);
            } else {
                const updatedDatasets = [...localDatasetsOrder];
                const [draggedItem] = updatedDatasets.splice(
                    draggedItemIndex,
                    1
                );
                updatedDatasets.splice(index, 0, draggedItem);
                setLocalDatasetsOrder(updatedDatasets);
            }
            setDraggedItemIndex(index);
        }
    };

    if (selectedDatasets.length === 0) {
        cnParts.push("empty-datasets-container-wrapper");
    }

    const handleDragEnd = () => {
        if (isGroupedDatasets) {
            handleGroupReorder(localGroupsOrder);
        } else {
            handleDatasetReorder(localDatasetsOrder);
        }
        setDraggedItemIndex(null);
        document.body.classList.remove("dragging");
    };

    const handleDatasetReorderInGroup = (
        groupName: string,
        updatedDatasets: StoredDataset[]
    ) => {
        const updatedGroups = selectedDatasetGroups.map((group) =>
            group.name === groupName
                ? { ...group, group: updatedDatasets }
                : group
        );
        handleGroupReorder(updatedGroups);
    };

    const handleGroupNameClick = (groupName: string) => {
        setCurrentGroupName(groupName);
        setIsEditGroupModalOpen(true);
    };

    const handleGroupNameChange = (newGroupName: string) => {
        const updatedGroups = selectedDatasetGroups.map((group) =>
            group.name === currentGroupName
                ? { ...group, name: newGroupName }
                : group
        );
        handleGroupReorder(updatedGroups);
        setIsEditGroupModalOpen(false);
    };

    const handleDeleteGroupClicked = () => {
        setIsDeleteDatasetGroupModalOpen(true);
        setIsEditGroupModalOpen(false);
    };

    const handleDeleteGroupConfirmed = () => {
        if (handleDismissGroup) {
            handleDismissGroup(currentGroupName);
        }
        setIsDeleteDatasetGroupModalOpen(false);
    };

    const handleDeleteDatasetsGroupModalClose = () => {
        setIsDeleteDatasetGroupModalOpen(false);
        setIsEditGroupModalOpen(true);
    };

    const selectedDatasetsCn = isGroupedDatasets
        ? "selected-datasets selected-grouped-datasets"
        : "selected-datasets";

    const selectedDatasetsContainerCn = isLoading
        ? "loading selected-datasets"
        : selectedDatasetsCn;

    const noSelectedDatasetsTestId = testId
        ? `${testId}-no-selected-datasets`
        : undefined;

    const cn = cnParts.join(" ");

    return (
        <div className={cn}>
            <DeleteDatasetsGroupModal
                className="delete-grouped-datasets-modal"
                isDeletingAllGroups={false}
                groupName={currentGroupName}
                totalDatasets={
                    selectedDatasetGroups?.find(
                        (group) => group.name === currentGroupName
                    )?.group.length as number
                }
                isOpen={isDeleteDatasetGroupModalOpen}
                onDeleteGroup={handleDeleteGroupConfirmed}
                onRequestCancel={handleDeleteDatasetsGroupModalClose}
                onRequestClose={() => setIsDeleteDatasetGroupModalOpen(false)}
            />
            <EditDatasetsGroupModal
                isOpen={isEditGroupModalOpen}
                groupName={currentGroupName}
                existingGroupNames={selectedDatasetGroups.map(
                    (group) => group.name
                )}
                onDeleteGroup={handleDeleteGroupClicked}
                onGroupNameChange={handleGroupNameChange}
                onRequestClose={() => setIsEditGroupModalOpen(false)}
                testId={testId ? `${testId}-edit-group-modal` : undefined}
            />
            <ul className={selectedDatasetsContainerCn} data-testid={testId}>
                {isGroupedDatasets ? (
                    localGroupsOrder.length > 0 ? (
                        localGroupsOrder.map((group, index) => (
                            <SelectedDatasetGroup
                                key={group.name}
                                name={group.name}
                                group={group.group}
                                handleDismissDataset={handleDismissDataset}
                                handleDatasetReorder={
                                    handleDatasetReorderInGroup
                                }
                                handleDragStart={(event) =>
                                    handleDragStart(event, index)
                                }
                                handleDragOver={(event) =>
                                    handleDragOver(event, index)
                                }
                                handleDragEnd={handleDragEnd}
                                onGroupNameClick={() =>
                                    handleGroupNameClick(group.name)
                                }
                                testId={`${testId}-group-${index}`}
                            />
                        ))
                    ) : (
                        <div
                            className="no-datasets"
                            data-testid={noSelectedDatasetsTestId}
                        >
                            <div className="no-datasets-message">
                                <div className="top-message">
                                    No Dataset Groups
                                </div>
                                <div className="bottom-message">
                                    Add Datasets to groups to generate a report
                                </div>
                            </div>
                        </div>
                    )
                ) : selectedDatasets.length > 0 ? (
                    selectedDatasets.map((dataset, index) => (
                        <SelectedDataset
                            key={dataset.fileName}
                            dataset={dataset.fileName}
                            handleDismissDataset={handleDismissDataset}
                            handleDragStart={(event) =>
                                handleDragStart(event, index)
                            }
                            handleDragOver={(event) =>
                                handleDragOver(event, index)
                            }
                            handleDragEnd={handleDragEnd}
                            className={
                                draggedItemIndex === index ? "dragging" : ""
                            }
                            testId={testId}
                        />
                    ))
                ) : (
                    <div
                        className="no-datasets"
                        data-testid={noSelectedDatasetsTestId}
                    >
                        <div className="no-datasets-message">
                            <div className="top-message">
                                No Datasets Selected
                            </div>
                            <div className="bottom-message">
                                Please choose your datasets
                            </div>
                        </div>
                    </div>
                )}
            </ul>
        </div>
    );
}
