export function ColumnsRenderer() {
    return [
        {
            Header: "Name",
            accessor: "fileName",
            filterLabel: "Name",
            id: "fileName",
            filter: "includesValue"
        },
        {
            header: "Sample ID",
            accessor: "metadata",
            filterLabel: "Sample ID",
            id: "metadata",
            filter: "includesValue"
        },
        {
            Header: "Type",
            accessor: "fileType",
            filterLabel: "Type",
            id: "fileType",
            filter: "includesValue"
        },
        {
            Header: "Size",
            accessor: "fileSize",
            filterLabel: "Size",
            id: "fileSize",
            filter: "includesValue"
        },
        {
            Header: "Data Format",
            accessor: "dataFormat",
            filterLabel: "Data Format",
            id: "dataFormat",
            filter: "includesValue"
        },
        {
            Header: "",
            accessor: "removeFile",
            filterLabel: "Remove File",
            id: "removeFile",
            filter: "includesValue"
        }
    ];
}
