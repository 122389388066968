import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";
import {
    CyRheologyParametersForViscosityAnalysis,
    ViscosityParameters
} from "../../../interfaces/analysis-types";
import {
    ValidateDataType,
    ValidateNValue,
    ValidateReferenceTemp,
    ValidateDatasets
} from "../cy-rheology/cy-rheology-validations";

export function ValidateCyRheologyParametersForViscosity(
    params: CyRheologyParametersForViscosityAnalysis
): boolean {
    return (
        ValidateDataType(params.dataType) &&
        ValidateNValue(params.fixN, params.nValue) &&
        ValidateReferenceTemp(params.referenceTemp)
    );
}

export function ValidateDatasetGroups(
    datasetGroups: StoredDatasetGroup[]
): boolean {
    if (!datasetGroups || datasetGroups.length === 0) {
        return false;
    }
    if (
        !datasetGroups.every((group) => group.group && group.group.length > 0)
    ) {
        return false;
    }

    return true;
}

export function ValidateDatasetsOrGroups(
    selectedDatasets: StoredDataset[],
    selectedDatasetGroups: StoredDatasetGroup[]
): boolean {
    if (selectedDatasetGroups && selectedDatasetGroups.length > 0) {
        return ValidateDatasetGroups(selectedDatasetGroups);
    }
    if (selectedDatasets && selectedDatasets.length > 0) {
        return ValidateDatasets(selectedDatasets.length);
    }
    return false;
}

export function ValidateViscosityForm(params: ViscosityParameters): boolean {
    const isDatasetsValid = ValidateDatasetsOrGroups(
        params.selectedDatasets,
        params.selectedDatasetGroups
    );
    const isCyRheologyValid = ValidateCyRheologyParametersForViscosity(
        params.cyRheologyParams
    );
    const isViscosityParamsValid =
        params.submittedBy.trim() !== "" && params.description.trim() !== "";

    return isDatasetsValid && isCyRheologyValid && isViscosityParamsValid;
}
