import React, { ComponentType } from "react";
import {
    LDLogger,
    LDOptions,
    LDProvider,
    useFlags
} from "launchdarkly-react-client-sdk";

import { AccountInformation } from "@cpchem/azure-auth";
import {
    getAppVersionFromMetaTag,
    LogLevel,
    LogManager
} from "@cpchem/logging";

import {
    FeatureFlagContext,
    FeatureFlagProviderProps,
    FeatureFlags,
    FeatureFlagService
} from "../interface";
import { getHashCode } from "../../../utilities/get-hash-code";

export class LaunchDarklyFeatureFlagServiceImplementation
    implements FeatureFlagService
{
    private readonly clientSideId: string;

    constructor(clientSideId: string) {
        this.clientSideId = clientSideId;
    }

    getProviderComponent(): ComponentType<FeatureFlagProviderProps> {
        const options: LDOptions = {
            application: {
                id: "space-frontend",
                version: getAppVersionFromMetaTag()
            },
            privateAttributes: ["username", "org"],
            logger: LaunchDarklyFeatureFlagServiceImplementation.createLogger()
        };

        const LaunchDarklyFeatureFlagContextProvider = (
            props: FeatureFlagProviderProps
        ) => {
            return (
                <LDProvider
                    clientSideID={this.clientSideId}
                    deferInitialization
                    options={options}
                    context={props.context}
                >
                    {props.children}
                </LDProvider>
            );
        };

        return LaunchDarklyFeatureFlagContextProvider;
    }

    // eslint-disable-next-line class-methods-use-this
    getFlagsHook(): () => FeatureFlags {
        return useFlags;
    }

    // eslint-disable-next-line class-methods-use-this
    async createContext(user: AccountInformation): Promise<FeatureFlagContext> {
        const key = await getHashCode(user.username, 16);

        return {
            kind: "user",
            key: key,

            // custom attributes
            username: user?.username,
            org: user?.organizationalDetails
        };
    }

    private static createLogger(): LDLogger {
        function formatMessage(message: string): string {
            return `[${new Date().toUTCString()}] : LDProvider : ${message}`;
        }

        return {
            debug: (message: string) =>
                LogManager.current().log(
                    formatMessage(message),
                    LogLevel.DEBUG
                ),
            error: (message: string) =>
                LogManager.current().log(
                    formatMessage(message),
                    LogLevel.ERROR
                ),
            info: (message: string) =>
                LogManager.current().log(formatMessage(message), LogLevel.INFO),
            warn: (message: string) =>
                LogManager.current().log(formatMessage(message), LogLevel.WARN)
        };
    }
}
