import React from "react";
import { Select } from "@cpchem/covalence-ui";
import { CyResinTypes } from "../../../../interfaces/analysis-types";

interface ResinTypeSectionProps {
    resinType: CyResinTypes;
    onChange: (value: CyResinTypes) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function ResinTypeSection({
    resinType,
    onChange,
    isDisabled,
    testId
}: ResinTypeSectionProps): JSX.Element {
    const resinTypes = [
        { key: "PE", value: "PE", text: "PE" },
        { key: "PP", value: "PP", text: "PP" },
        { key: "PS", value: "PS", text: "PS" },
        { key: "Other", value: "Other", text: "Other" }
    ];

    return (
        <div
            className="parameter-section two-items resin-type"
            data-testid={testId}
        >
            <h4>Resin Type</h4>
            <Select
                className="resin-type-select"
                label="Resin Type"
                isLabelHidden
                options={resinTypes}
                value={resinType}
                onChange={(value) => onChange(value as CyResinTypes)}
                isDisabled={isDisabled}
                testId={`${testId}-select`}
            />
        </div>
    );
}
