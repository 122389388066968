import React, { useState } from "react";
import { ViscosityAnalysisResponse } from "@services/space/analysis/viscosity/models";
import { Carousel } from "../../../components/carousel/carousel";
import ViscosityOverlayPreviewChartDataProvider from "./viscosity-overlay-preview-chart-data-provider";

export interface IMultiViscosityOverlayChartDataProviderProps {
    RawData: ViscosityAnalysisResponse;
    testId?: string;
}

const MultiViscosityOverlayChartDataProvider: React.FC<
    IMultiViscosityOverlayChartDataProviderProps
> = (props: IMultiViscosityOverlayChartDataProviderProps) => {
    const { RawData, testId } = props;

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const divs = RawData.sampleGroups.map((group) => {
        const { temperatures } = group.summary;
        const tempString =
            temperatures && temperatures.length > 0
                ? temperatures.join(", ")
                : "190";
        return (
            <ViscosityOverlayPreviewChartDataProvider
                key={group.name}
                RawData={group}
                Temp={tempString}
            />
        );
    });

    return (
        <div>
            <Carousel
                label={RawData.sampleGroups[activeItemIndex].name}
                testId={testId}
                initialActiveItemIndex={0}
                onActiveItemIndexChange={(idx) => setActiveItemIndex(idx)}
            >
                {divs}
            </Carousel>
        </div>
    );
};

export default MultiViscosityOverlayChartDataProvider;
