import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app";
import Config from "./config";
import { LoginScreen, UnauthenticatedPage } from "./pages";
import { AuthManager } from "@cpchem/azure-auth";
import { AuthenticationBoundary } from "@cpchem/azure-auth-react";
import { configureLogging } from "./logging/configure-logging";
import { configureLogRocket } from "./logging/configure-log-rocket";
import {
    ServiceLocator,
    PersonalizationServiceKey,
    AuthServiceKey,
    SpaceServiceKey
} from "@services";
import {
    FetchInterceptorImplementation,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { PersonalizationServiceImplementation } from "@services/personalization";
import { DatasetServiceImplementation } from "@services/space/datasets/implementations";
import {
    AlertBannerProvider,
    Drawer,
    Modal,
    ToastProvider
} from "@cpchem/covalence-ui";
import "./index.scss";
import { getDevAccountMock } from "./utilities/use-devmode-user";
import { ServiceProvider } from "./service-provider";
import { DatasetProvider } from "./stores/datasets/datasets-context";
import { FeatureFlagService } from "@services/feature-flags/interface";
import { LaunchDarklyFeatureFlagServiceImplementation } from "@services/feature-flags/launchdarkly/implementation";
import { FeatureFlagServiceKey } from "@services/feature-flags";
import { MockFeatureFlagServiceImplementation } from "@services/feature-flags/mock/implementation";

configureLogging(Config);

const authSettings = {
    clientId: Config.msal.clientId,
    authority: Config.msal.authority ?? "",
    redirectUri: Config.msal.redirectUri ?? "",
    scopes: [
        ...Config.api.personalization.scopes,
        ...Config.api.datasetFormats.scopes
    ],
    domainHint: "cpchem.com"
};

let authManagerInstance: AuthManager;
if (!Config.connected) {
    authManagerInstance = AuthManager.getInstance();
    const mockAccountGenerator = await getDevAccountMock();
    authManagerInstance.loadConfiguration(
        "Dev",
        undefined,
        mockAccountGenerator
    );

    // Faked APIs
    const { worker } = await import("./api-mocks/browser");
    worker.start();
} else {
    authManagerInstance = AuthManager.getInstance();
    authManagerInstance.loadConfiguration("MSAL", authSettings);

    configureLogRocket(Config.logging.logRocketAppId);
}

// configure feature flags
let featureFlagService: FeatureFlagService;
if (!Config.connected) {
    // set mock feature flag values here
    const mockFlags = {
        enableViscosityReport: true
    };

    featureFlagService = new MockFeatureFlagServiceImplementation(mockFlags);
} else {
    featureFlagService = new LaunchDarklyFeatureFlagServiceImplementation(
        Config.featureFlags.launchDarkly.clientSideId
    );
}

// Configure Services in Service Locator
const locator = ServiceLocator;
locator.set(AuthServiceKey, authManagerInstance);
locator.set(FeatureFlagServiceKey, featureFlagService);
locator.set(FetchServiceKey, new FetchInterceptorImplementation());
locator.set(
    PersonalizationServiceKey,
    new PersonalizationServiceImplementation()
);
locator.set(SpaceServiceKey, new DatasetServiceImplementation());

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);
Drawer.setAppElement(rootElement);
Modal.setAppElement(rootElement);

const app = (
    <ServiceProvider serviceLocator={locator}>{<App />}</ServiceProvider>
);

root.render(
    <React.StrictMode>
        <Router>
            <DatasetProvider>
                <AlertBannerProvider>
                    <ToastProvider>
                        <AuthenticationBoundary
                            showOnUnknown={<LoginScreen />}
                            showOnAuthenticated={app}
                            showOnUnAuthenticated={<UnauthenticatedPage />}
                        />
                    </ToastProvider>
                </AlertBannerProvider>
            </DatasetProvider>
        </Router>
    </React.StrictMode>
);
