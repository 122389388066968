import {
    AnalysisService,
    BaseAnalysisRequest,
    ReportFormat
} from "@services/space/analysis/base-models";
import { RheologyAnalysisServiceImplementation } from "@services/space/analysis/cy-rheology/implementations";
import { ApiErrorResponse, ApiFileResponse } from "@services/api-response";
import { RheologyTTSServiceImplementation } from "@services/space/analysis/cy-rheology/rheology-tts-implementations";
import { ViscosityAnalysisServiceImplementation } from "@services/space/analysis/viscosity/implementations";
import { ViscosityTTSServiceImplementation } from "@services/space/analysis/viscosity/viscosity-tts-implementations";

const analysisServiceDictionary: { [key: string]: () => AnalysisService } = {
    cyRheology: () => new RheologyAnalysisServiceImplementation(),
    cyRheologyTTS: () => new RheologyTTSServiceImplementation(),
    viscosity: () => new ViscosityAnalysisServiceImplementation(),
    viscosityTTS: () => new ViscosityTTSServiceImplementation()
    // Add more analysis types and their corresponding services here
};

export const fetchAnalysisReport = async (
    request: BaseAnalysisRequest,
    reportFormat: ReportFormat
): Promise<ApiFileResponse | ApiErrorResponse> => {
    const serviceFactory =
        analysisServiceDictionary[
            request.analysisType as keyof typeof analysisServiceDictionary
        ];

    if (!serviceFactory) {
        throw new Error(`Unsupported analysis type: ${request.analysisType}`);
    }

    const service = serviceFactory();

    // console.log("API Request:", request);
    try {
        const response = await service.fetchAnalysisReport(
            request,
            reportFormat
        );
        // console.log("API Response:", response);
        return response;
    } catch (error) {
        console.error("Error performing analysis:", error);
        throw error;
    }
};
