import React, { useEffect } from "react";
import {
    CyResinTypes,
    ViscosityParameters
} from "../../../../interfaces/analysis-types";
import { ReportInformationSection } from "./report-information-section";
import { ResinInformationSection } from "./resin-information-section";
import { TableOfCyParametersSection } from "./table-Of-Cy-Parameters-section";
import { ResinTypeData } from "@services/space/resinTypes/models";
import { Divider } from "@cpchem/covalence-ui";

interface ViscosityParametersSectionProps {
    parameters: ViscosityParameters;
    resinDataMap: { [key: string]: ResinTypeData };
    onUpdate: (updates: Partial<ViscosityParameters>) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    testId?: string;
}

export function ViscosityParametersSection({
    parameters,
    resinDataMap,
    onUpdate,
    onValidation,
    isLoading,
    testId
}: ViscosityParametersSectionProps): JSX.Element {
    const handleFieldUpdate = <K extends keyof ViscosityParameters>(
        field: K,
        value: ViscosityParameters[K]
    ) => {
        onUpdate({ [field]: value });
    };

    useEffect(() => {
        const isValid =
            (parameters.submittedBy?.trim() ?? "") !== "" &&
            (parameters.description?.trim() ?? "") !== "";
        onValidation(isValid);
    }, [parameters.submittedBy, parameters.description, onValidation]);

    const handleResinTypeChange = (value: CyResinTypes) => {
        const resinData = resinDataMap[value as keyof typeof resinDataMap];
        if (resinData) {
            onUpdate({
                resinType: value,
                rho0: resinData.referenceMeltDensity.toString(),
                g0est: resinData.plateauModulus.toString(),
                drdt: resinData.meltDensityDelta.toString(),
                resinLabel: resinData.abbreviation
            });
        }
    };

    return (
        <div className="viscosity-parameters-section">
            <ReportInformationSection
                submitter={parameters.submittedBy}
                description={parameters.description}
                onSubmitterChange={(value) =>
                    handleFieldUpdate("submittedBy", value)
                }
                onDescriptionChange={(value) =>
                    handleFieldUpdate("description", value)
                }
                isDisabled={isLoading}
                testId={`${testId}-report-information`}
            />
            <Divider className="viscosity-parameters-divider" />

            <ResinInformationSection
                resinType={parameters.resinType}
                rho0={parameters.rho0}
                g0est={parameters.g0est}
                drdt={parameters.drdt}
                resinLabel={parameters.resinLabel}
                resinDataMap={resinDataMap}
                onResinTypeChange={handleResinTypeChange}
                onRho0Change={(value: string) =>
                    handleFieldUpdate("rho0", value)
                }
                onG0estChange={(value: string) =>
                    handleFieldUpdate("g0est", value)
                }
                onDrdtChange={(value: string) =>
                    handleFieldUpdate("drdt", value)
                }
                onResinLabelChange={(value: string) =>
                    handleFieldUpdate("resinLabel", value)
                }
                isDisabled={isLoading}
                testId={`${testId}-resin-information`}
            />
            <Divider className="viscosity-parameters-divider" />
            <TableOfCyParametersSection
                tableOfCyParameters={parameters.summaryAttributes}
                onTableOfCyParametersChange={(value) =>
                    handleFieldUpdate("summaryAttributes", value)
                }
                isDisabled={isLoading}
                testId={`${testId}-cy-parameters`}
            />
        </div>
    );
}
