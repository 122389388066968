import React, { useCallback } from "react";
import { DatasetsSelector } from "../../datasets-selector/datasets-selector";
import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";

interface DatasetsSectionProps {
    selectedDatasets?: StoredDataset[];
    selectedDatasetGroups?: StoredDatasetGroup[];
    onUpdateSelectedDatasets?: (datasets: StoredDataset[]) => void;
    onUpdateSelectedDatasetGroups?: (
        datasetGroups: StoredDatasetGroup[]
    ) => void;
    isGroupedDatasets: boolean;
    isLoading?: boolean;
    testId?: string;
}

export function DatasetsSection({
    selectedDatasets,
    selectedDatasetGroups,
    onUpdateSelectedDatasets,
    onUpdateSelectedDatasetGroups,
    isGroupedDatasets,
    isLoading,
    testId
}: DatasetsSectionProps): JSX.Element {
    const handleDatasetsSelection = useCallback(
        (datasets: StoredDataset[], groupNames: string[]) => {
            if (isGroupedDatasets && onUpdateSelectedDatasetGroups) {
                const updatedGroups = (selectedDatasetGroups || []).map(
                    (group) => {
                        if (groupNames.includes(group.name)) {
                            const newDatasetsToAdd = datasets.filter(
                                (d) =>
                                    !group.group.some(
                                        (dataset) =>
                                            dataset.fileName === d.fileName
                                    )
                            );
                            return {
                                ...group,
                                group: [...group.group, ...newDatasetsToAdd]
                            };
                        }
                        return group;
                    }
                );
                onUpdateSelectedDatasetGroups(updatedGroups);
            } else if (!isGroupedDatasets && onUpdateSelectedDatasets) {
                const newDatasets = datasets.filter(
                    (d) =>
                        !(selectedDatasets || []).some(
                            (sd) => sd.fileName === d.fileName
                        )
                );

                if (newDatasets && newDatasets.length > 0) {
                    onUpdateSelectedDatasets([
                        ...(selectedDatasets || []),
                        ...newDatasets
                    ]);
                }
            }
        },
        [
            selectedDatasets,
            selectedDatasetGroups,
            onUpdateSelectedDatasets,
            onUpdateSelectedDatasetGroups,
            isGroupedDatasets
        ]
    );

    const handleDismissGroup = useCallback(
        (groupName: string) => {
            if (onUpdateSelectedDatasetGroups && selectedDatasetGroups) {
                const updatedGroups = selectedDatasetGroups.filter(
                    (group) => group.name !== groupName
                );
                onUpdateSelectedDatasetGroups(updatedGroups);
            }
        },
        [selectedDatasetGroups, onUpdateSelectedDatasetGroups]
    );

    const handleDismissAllGroups = useCallback(() => {
        if (onUpdateSelectedDatasetGroups) {
            onUpdateSelectedDatasetGroups([]);
        }
    }, [onUpdateSelectedDatasetGroups]);

    const handleDatasetReorder = useCallback(
        (updatedDatasets: StoredDataset[]) => {
            onUpdateSelectedDatasets?.(updatedDatasets);
        },
        [onUpdateSelectedDatasets]
    );

    const handleGroupReorder = useCallback(
        (updatedGroups: StoredDatasetGroup[]) => {
            onUpdateSelectedDatasetGroups?.(updatedGroups);
        },
        [onUpdateSelectedDatasetGroups]
    );

    return (
        <DatasetsSelector
            selectedDatasets={selectedDatasets}
            selectedDatasetGroups={selectedDatasetGroups}
            isGroupedDatasets={isGroupedDatasets}
            onSelectDatasets={handleDatasetsSelection}
            onHandleDismissGroup={handleDismissGroup}
            onHandleDismissAllGroups={handleDismissAllGroups}
            onHandleDatasetReorder={handleDatasetReorder}
            onHandleGroupReorder={handleGroupReorder}
            hasTitle
            isLoading={isLoading}
            testId={testId}
        />
    );
}
