import React, { useState } from "react";
import { SpaceMenu } from "../../../space-menu/space-menu";
import { Button, Checkbox } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface AddToGroupMenuProps {
    existingGroupNames: string[];
    selectedGroups: string[];
    onGroupSelectionChange: (groupName: string, isSelected: boolean) => void;
    onSelectAllGroups: (isSelected: boolean) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function AddToGroupMenu({
    existingGroupNames,
    selectedGroups,
    onGroupSelectionChange,
    onSelectAllGroups,
    isDisabled,
    testId
}: AddToGroupMenuProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const allGroupsSelected =
        selectedGroups.length === existingGroupNames.length &&
        existingGroupNames.length > 0;

    const addButtonIcon = isMenuOpen
        ? (faCaretUp as IconProp)
        : (faCaretDown as IconProp);

    const baseButton = (
        <Button
            text="Add To"
            isDisabled={isDisabled}
            color="primary"
            isIconAfterText
            icon={<FontAwesomeIcon icon={addButtonIcon} />}
            testId={`${testId}-add-to-button`}
        />
    );

    const menuContent = (
        <div className="menu-items" data-testid={testId}>
            {existingGroupNames.length > 1 && (
                <div className="select-all-container sticky">
                    <Checkbox
                        label="Select All"
                        isChecked={allGroupsSelected}
                        onChange={(checked) => onSelectAllGroups(checked)}
                        testId={`${testId}-select-all`}
                    />
                </div>
            )}
            <div className="groups-container">
                {existingGroupNames.map((groupName) => (
                    <div key={groupName} className="group-item">
                        <Checkbox
                            label={groupName}
                            isChecked={selectedGroups.includes(groupName)}
                            onChange={(checked) =>
                                onGroupSelectionChange(groupName, checked)
                            }
                            testId={`${testId}-${groupName}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <SpaceMenu
            baseElement={baseButton}
            isScrollable={existingGroupNames.length > 5}
            isMenuOntop
            onToggle={(isOpen) => setIsMenuOpen(isOpen)}
        >
            {menuContent}
        </SpaceMenu>
    );
}
