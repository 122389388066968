import Plot from "react-plotly.js";
import React, { useState } from "react";
import { PlotData, Layout } from "plotly.js";

export interface ITargetComparisonGraphProps {
    data: PlotData[];
    targetSampleName: string;
    temp: string;
    testId?: string;
}

const TargetComparisonPreviewChart: React.FC<ITargetComparisonGraphProps> = (
    props: ITargetComparisonGraphProps
) => {
    const { data, targetSampleName, temp, testId } = props;
    const [visibility, setVisibility] = useState<boolean[]>(
        data.map(() => true)
    );
    const handleLegendClick = (index: number) => {
        const newVisibility = [...visibility];
        newVisibility[index] = !newVisibility[index];
        setVisibility(newVisibility);
    };
    const layout: Partial<Layout> = {
        title: `Dynamic Melt Viscosity vs Frequency<br><sup>Measured at <span id="analysis-temp">${temp}</span> °C</sup><br><sup>Target: ${targetSampleName}</sup>`,
        xaxis: {
            title: {
                text: "Frequency (rad/s)",
                standoff: 0
            },
            dtick: 1,
            type: "log",
            autorange: true,
            exponentformat: "power",
            automargin: true
        },
        yaxis: {
            title: "Viscosity Percent Difference (%)",
            autorange: true,
            automargin: true
        },
        autosize: false,
        showlegend: true,
        legend: { orientation: "h", yref: "container", itemwidth: 60 },
        width: 800,
        height: 550
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLegendClickPlotly = (eventData: any) => {
        if (eventData.curveNumber !== undefined) {
            handleLegendClick(eventData.curveNumber);
        }
    };

    const previewChartTestId = testId ? `${testId}-preview-chart` : undefined;

    return (
        <div className="plot-wrapper" data-testid={previewChartTestId}>
            <Plot
                divId="preview-chart"
                data={data}
                layout={layout}
                className="plotly-chart-size"
                useResizeHandler={false}
                onClick={handleLegendClickPlotly}
            />
        </div>
    );
};

export default TargetComparisonPreviewChart;
