import config from "@config";
import { ApiResponse, ServiceLocator } from "@services";
import {
    FetchInterceptorService,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { FetchOptionMethod, FetchOptions } from "@services/fetch-interfaces";
import { log, LogLevel } from "@cpchem/logging";
import { AuthManager } from "@cpchem/azure-auth";
import {
    ApiFileResponse,
    getApiFileResponse,
    errorResponseDictionary,
    errorResponseValue,
    ApiErrorResponse
} from "@services/api-response";
import { ViscosityAnalysisRequest, ViscosityAnalysisResponse } from "./models";
import {
    ReportFormat,
    BaseAnalysisRequest,
    BaseAnalysisResponse,
    AnalysisService
} from "../base-models";
import { ViscosityAnalysisService } from "./interface";

const spaceResponseDictionary: errorResponseValue = {
    ...errorResponseDictionary
};

function defaultErrorResponse(response: Response): ApiErrorResponse {
    log(
        `Unknown error when attempting to retrieve data. Status: ${response.statusText}`,
        LogLevel.ERROR
    );
    return {
        title: response.statusText
    };
}

export class ViscosityTTSServiceImplementation
    implements ViscosityAnalysisService, AnalysisService
{
    private readonly baseUrl = config.api.viscosityTTS.url;
    private readonly scopes = config.api.viscosityTTS.scopes;

    interceptor: FetchInterceptorService;
    authMgr: AuthManager;

    constructor(authManager?: AuthManager) {
        this.authMgr = authManager ?? AuthManager.getInstance();
        this.interceptor =
            ServiceLocator.get<FetchInterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        scopes: string[],
        method: FetchOptionMethod,
        body?: Blob | ArrayBuffer | string | null
    ): Promise<FetchOptions> {
        if (!this.interceptor) {
            throw new Error("FetchInterceptorService is not initialized.");
        }
        return await this.interceptor.getFetchOptionsAsync(
            scopes,
            method,
            body
        );
    }

    async fetchAnalysisResults(
        request: BaseAnalysisRequest
    ): Promise<ApiResponse<BaseAnalysisResponse> | ApiErrorResponse> {
        if (request.analysisType === "viscosityTTS") {
            return this.fetchViscosityAnalysisResults(
                request as ViscosityAnalysisRequest
            );
        }
        throw new Error(`Unsupported analysis type: ${request.analysisType}`);
    }

    async fetchAnalysisReport(
        request: BaseAnalysisRequest,
        reportFormat: ReportFormat
    ): Promise<ApiFileResponse | ApiErrorResponse> {
        if (request.analysisType === "viscosityTTS") {
            return this.fetchViscosityAnalysisReport(
                request as ViscosityAnalysisRequest,
                reportFormat
            );
        }
        throw new Error(`Unsupported analysis type: ${request.analysisType}`);
    }

    async fetchViscosityAnalysisResults(
        request: ViscosityAnalysisRequest
    ): Promise<ApiResponse<ViscosityAnalysisResponse> | ApiErrorResponse> {
        const uri = `${this.baseUrl}`;
        const options = await this.ensureFetchOptionsAsync(
            this.scopes,
            "POST",
            JSON.stringify(request)
        );
        options.body = JSON.stringify(request);
        options.method = "POST";

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        const error = await res.json();
        if (error) {
            return error as ApiErrorResponse;
        }

        if (spaceResponseDictionary[res.status]) {
            return spaceResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }

    async fetchViscosityAnalysisReport(
        request: ViscosityAnalysisRequest,
        reportFormat: ReportFormat
    ): Promise<ApiFileResponse | ApiErrorResponse> {
        const uri = `${this.baseUrl}?reportFormat=${reportFormat}`;
        const options = await this.ensureFetchOptionsAsync(
            this.scopes,
            "POST",
            JSON.stringify(request)
        );
        options.body = JSON.stringify(request);
        options.method = "POST";

        const res = await fetch(uri, options);

        if (res.ok) {
            return await getApiFileResponse(res);
        }

        const error = await res.json();
        if (error) {
            return error as ApiErrorResponse;
        }

        if (spaceResponseDictionary[res.status]) {
            return spaceResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }
}
