export async function getHashCode(
    data: string,
    length?: number
): Promise<string> {
    const plainUtf8 = new TextEncoder().encode(data);

    const hash = await window.crypto.subtle.digest("SHA-256", plainUtf8);
    const hashUtf8 = new Uint8Array(hash);

    return Array.from(hashUtf8)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
        .slice(0, length);
}
