import { ResinTypesServiceImplementation } from "@services/space/resinTypes/implementations";
import { ResinTypeData } from "@services/space/resinTypes/models";
import { ApiErrorResponse } from "@services/api-response";

export const fetchResinData = async (): Promise<{
    [key: string]: ResinTypeData;
}> => {
    try {
        const resinService = new ResinTypesServiceImplementation();
        const response = await resinService.getAllResinTypes();

        if ("data" in response) {
            const resinTypesData = (
                response.data as { resinTypes: ResinTypeData[] }
            ).resinTypes.reduce(
                (acc, resin) => {
                    acc[resin.abbreviation] = resin;
                    return acc;
                },
                {} as { [key: string]: ResinTypeData }
            );

            return resinTypesData;
        }
        console.error(
            "Failed to fetch resin data:",
            (response as ApiErrorResponse).title
        );
        return {};
    } catch (error) {
        console.error("Error fetching resin data:", error);
        return {};
    }
};
