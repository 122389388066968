import { ApiErrorResponse, ApiResponse } from "@services/api-response";
import { ViscositySummaryAttributeTypesServiceImplementation } from "@services/space/viscositySummary/implementations";
import { ViscositySummaryAttributeTypesResponse } from "@services/space/viscositySummary/models";

export const fetchCYParametersData = async (): Promise<
    {
        label: string;
        value: string;
    }[]
> => {
    try {
        const cyService =
            new ViscositySummaryAttributeTypesServiceImplementation();
        const response = await cyService.getAllViscositySummaryAttributeTypes();

        if ("data" in response) {
            const cyParametersData = (
                response as ApiResponse<ViscositySummaryAttributeTypesResponse>
            ).data.attributeTypes.map((item) => ({
                label: item.title,
                value: item.name
            }));

            return cyParametersData;
        }

        console.error(
            "Failed to fetch CY parameters data:",
            (response as ApiErrorResponse).title
        );
        return [];
    } catch (error) {
        console.error("Error fetching CY parameters data:", error);
        return [];
    }
};
