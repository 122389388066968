import React from "react";
import { TextField } from "@cpchem/covalence-ui";
import { handleNumericChange } from "../../../../utilities/report-analysis/handleNumericChange";

interface FixNSectionProps {
    fixN: boolean;
    nValue: string | null;
    onFixNChange: (value: boolean) => void;
    onNValueChange: (value: string) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function FixNSection({
    fixN,
    nValue,
    onFixNChange,
    onNValueChange,
    isDisabled,
    testId
}: FixNSectionProps): JSX.Element {
    const handleChange = (value: string) => {
        handleNumericChange(value, onNValueChange);
    };

    return (
        <div
            className="parameter-section three-items fix-n-at"
            data-testid={testId}
        >
            <h4>Fix n at</h4>
            <div className="parameter-radio-buttons fix-n-at-radio-buttons">
                <label className="fix-n-yes">
                    <input
                        type="radio"
                        name="fix-n"
                        checked={fixN === true}
                        onChange={() => onFixNChange(true)}
                        disabled={isDisabled}
                        data-testid={`${testId}-yes`}
                    />
                    Yes
                </label>
                <label className="fix-n-no">
                    <input
                        type="radio"
                        name="fix-n"
                        checked={fixN === false}
                        onChange={() => onFixNChange(false)}
                        disabled={isDisabled}
                        data-testid={`${testId}-no`}
                    />
                    No
                </label>
            </div>
            <div className="fix-n-text-field parameters-text-field">
                <TextField
                    label="Fixed n Value"
                    isLabelHidden
                    value={nValue || ""}
                    onChange={handleChange}
                    isDisabled={!fixN || isDisabled}
                    testId={`${testId}-text-field`}
                />
            </div>
        </div>
    );
}
