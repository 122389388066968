import React from "react";
import { Checkbox, Select } from "@cpchem/covalence-ui";
import { StoredDataset } from "@services/space/datasets/models";

interface CompareDatasetsSectionProps {
    isComparingDatasets: boolean;
    selectedCompareDataset: string | null;
    selectedDatasets: StoredDataset[];
    onToggleComparingDatasets: (checked: boolean) => void;
    onSelectCompareDataset: (value: string) => void;
    isDisabled?: boolean;
    testId?: string;
}

export function CompareDatasetsSection({
    isComparingDatasets,
    selectedCompareDataset,
    selectedDatasets,
    onToggleComparingDatasets,
    onSelectCompareDataset,
    isDisabled,
    testId
}: CompareDatasetsSectionProps): JSX.Element {
    const options = selectedDatasets.map((d) => ({
        key: d.fileName,
        value: d.fileName,
        text: d.fileName
    }));

    return (
        <div
            className="parameter-section three-items compare-datasets"
            data-testid={testId}
        >
            <h4>Compare Dataset</h4>
            <div className="compare-dataset-checkbox">
                <Checkbox
                    label=""
                    isChecked={isComparingDatasets}
                    onChange={onToggleComparingDatasets}
                    isDisabled={isDisabled}
                    testId={`${testId}-checkbox`}
                />
            </div>

            <Select
                className="compare-dataset-select"
                label="Compare Datasets"
                isLabelHidden
                options={options}
                value={selectedCompareDataset || ""}
                onChange={onSelectCompareDataset}
                isDisabled={!isComparingDatasets || isDisabled}
            />
        </div>
    );
}
