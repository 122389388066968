import React from "react";
import { Card, DataGrid } from "@cpchem/covalence-ui";
import {
    ColumnsRenderer,
    HeaderRendererFull
} from "../../../../pages/datasets-page/components/data-grid";
import { RowRendererFull } from "../../../../pages/datasets-page/components/data-grid/row-renderer";
import { EmptySelectorGrid } from "./empty-selector-grid";
import { StoredDataset } from "@services/space/datasets/models";

interface DatasetsDataGridProps {
    filteredDatasets: StoredDataset[];
    selectedFiles: string[];
    onSelectFile: (fileName: string, isSelected: boolean) => void;
    onSelectAllFiles: () => void;
    allFilesSelected: boolean;
    dataGridTestId?: string;
    dataGridRowsTestId?: string;
}

export function DatasetsDataGrid({
    filteredDatasets,
    selectedFiles,
    onSelectFile,
    onSelectAllFiles,
    allFilesSelected,
    dataGridTestId,
    dataGridRowsTestId
}: DatasetsDataGridProps) {
    const dataGridEmpty =
        filteredDatasets.length === 0 ? "empty-data-grid" : undefined;
    const gridWrapperCn = ["datasets-grid-wrapper", dataGridEmpty]
        .filter(Boolean)
        .join(" ");

    const gridProps = {
        data: filteredDatasets,
        columns: ColumnsRenderer(),
        useStickyHeaders: true,
        headerRenderer: () => (
            <HeaderRendererFull
                allFilesSelected={allFilesSelected}
                onSelectAllFiles={onSelectAllFiles}
                isDatasetsPage={false}
            />
        ),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rowRenderer: (row: any) =>
            RowRendererFull(
                row,
                onSelectFile,
                selectedFiles,
                false,
                undefined,
                undefined,
                dataGridRowsTestId
            ),
        testId: dataGridTestId
    };

    return (
        <Card className={gridWrapperCn}>
            <DataGrid {...gridProps} />
            {filteredDatasets.length === 0 && <EmptySelectorGrid />}
        </Card>
    );
}
