import { DatasetState } from "./datasets-types";

export const getDatasetFormats = (state: DatasetState) => state.datasetFormats;
export const getConvertData = (state: DatasetState) => state.convertData;
export const getLoadingStatus = (state: DatasetState) => state.loading;
export const getError = (state: DatasetState) => state.error;
export const getAnalysisResponse = (state: DatasetState) =>
    state.analysisResponse;
export const getResinTypes = (state: DatasetState) => state.resinTypes;
export const getAttributeTypes = (state: DatasetState) => state.attributeTypes;
