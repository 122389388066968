import config from "@config";
import { ApiResponse, ServiceLocator } from "@services";
import {
    FetchInterceptorService,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { FetchOptionMethod, FetchOptions } from "@services/fetch-interfaces";
import { log, LogLevel } from "@cpchem/logging";
import { AuthManager } from "@cpchem/azure-auth";
import {
    ApiErrorResponse,
    errorResponseDictionary,
    errorResponseValue
} from "@services/api-response";
import { ViscositySummaryAttributeTypesService } from "./interface";
import { ViscositySummaryAttributeTypesResponse } from "./models";

const spaceResponseDictionary: errorResponseValue = {
    ...errorResponseDictionary
};

function defaultErrorResponse(response: Response): ApiErrorResponse {
    log(
        `Unknown error when attempting to retrieve data. Status: ${response.statusText}`,
        LogLevel.ERROR
    );
    return {
        title: response.statusText
    };
}

export class ViscositySummaryAttributeTypesServiceImplementation
    implements ViscositySummaryAttributeTypesService
{
    private readonly attributeTypesBase =
        config.api.viscositySummaryAttributeTypes.url;
    private readonly attributeTypesScopes =
        config.api.viscositySummaryAttributeTypes.scopes;

    interceptor: FetchInterceptorService;
    authMgr: AuthManager;

    constructor(authManager?: AuthManager) {
        this.authMgr = authManager ?? AuthManager.getInstance();
        this.interceptor =
            ServiceLocator.get<FetchInterceptorService>(FetchServiceKey);
    }

    private async ensureFetchOptionsAsync(
        scopes: string[],
        method: FetchOptionMethod,
        body?: Blob | ArrayBuffer | string | null
    ): Promise<FetchOptions> {
        return await this.interceptor.getFetchOptionsAsync(
            scopes,
            method,
            body
        );
    }

    async getAllViscositySummaryAttributeTypes(): Promise<
        ApiResponse<ViscositySummaryAttributeTypesResponse> | ApiErrorResponse
    > {
        const uri = `${this.attributeTypesBase}`;

        const options = await this.ensureFetchOptionsAsync(
            this.attributeTypesScopes,
            "GET"
        );

        const res = await fetch(uri, options);

        if (res.ok) {
            const json = await res.json();
            return {
                data: json
            };
        }

        if (spaceResponseDictionary[res.status]) {
            return spaceResponseDictionary[res.status]();
        }
        return defaultErrorResponse(res);
    }
}
