import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";
import {
    defaultSelectedSummaryAttributes,
    SummaryAttributes
} from "./summary-attributes";

export type AnalysisType = "cyRheology" | "viscosity" | "other";

export type CyResinTypes = "PE" | "PP" | "PS" | "Other";
export type ViscosityResinTypes = "PE" | "PP" | "PS" | "Other";
export type CyDataTypes = "frequencySweep" | "tempFreqSweep";

export interface BaseAnalysisParameters {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface AnalysisParameters {
    analysisType: AnalysisType;
    /**
     * Please make sure to name any future analysis parameters in the format of `analysisTypeParams`
     * this is used in the useAnalysisParametersHandlers file to determine the key of the type of analysis
     * to update.
     */
    cyRheologyParams?: CyRheologyParameters;
    viscosityParams?: ViscosityParameters;
}

export interface CyRheologyParameters extends BaseAnalysisParameters {
    selectedDatasets: StoredDataset[];
    dataType: CyDataTypes;
    fixN: boolean;
    nValue: string | null;
    vogelTemp: string;
    referenceTemp: string;
    isComparingDatasets: boolean;
    selectedCompareDataset: string | null;
    isUsingYieldStressTerm: boolean;
    resinType: CyResinTypes;
}

export const defaultCyRheologyParameters: CyRheologyParameters = {
    selectedDatasets: [],
    dataType: "frequencySweep",
    fixN: true,
    nValue: "0.1818",
    vogelTemp: "0.0",
    referenceTemp: "190.0",
    isComparingDatasets: false,
    selectedCompareDataset: null,
    isUsingYieldStressTerm: false,
    resinType: "PE"
};

export interface CyRheologyParametersForViscosityAnalysis
    extends BaseAnalysisParameters {
    dataType: CyDataTypes;
    fixN: boolean;
    nValue: string | null;
    referenceTemp: string;
    vogelTemp: string;
    isUsingYieldStressTerm: boolean;
}

export const defaultCyRheologyParametersForViscosityAnalysis: CyRheologyParametersForViscosityAnalysis =
    {
        dataType: "frequencySweep",
        fixN: true,
        nValue: "0.1818",
        referenceTemp: "190.0",
        vogelTemp: "0.0",
        isUsingYieldStressTerm: false
    };

export interface ViscosityParameters extends BaseAnalysisParameters {
    selectedDatasetGroups: StoredDatasetGroup[];
    cyRheologyParams: CyRheologyParametersForViscosityAnalysis;
    submittedBy: string;
    description: string;
    resinType: ViscosityResinTypes;
    // TODO: These will be implemented in the future
    rho0: string;
    g0est: string;
    drdt: string;
    summaryAttributes: SummaryAttributes[];
}

export const defaultViscosityParameters: ViscosityParameters = {
    selectedDatasetGroups: [],
    cyRheologyParams: defaultCyRheologyParametersForViscosityAnalysis,
    submittedBy: "",
    description: "",
    resinType: "PE",
    rho0: "0.86379",
    g0est: "0.0005126",
    drdt: "200000",
    summaryAttributes: defaultSelectedSummaryAttributes
};

export const defaultAnalysisParameters: AnalysisParameters = {
    analysisType: "cyRheology",
    cyRheologyParams: defaultCyRheologyParameters
};
