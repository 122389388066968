import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { BaseAnalysisParameters } from "../../../interfaces/analysis-types";

interface BaseCyRheologyFormProps<T extends BaseAnalysisParameters> {
    initialParameters: T;
    onUpdate: (updates: T) => void;
    onValidation: (isValid: boolean) => void;
    isLoading?: boolean;
    validate: (params: T) => boolean;
    children: (props: {
        parameters: T;
        handleFieldUpdate: <K extends keyof T>(field: K, value: T[K]) => void;
        isLoading?: boolean;
    }) => React.ReactNode;
}

export function BaseCyRheologyForm<T extends BaseAnalysisParameters>({
    initialParameters,
    onUpdate,
    onValidation,
    isLoading = false,
    validate,
    children
}: BaseCyRheologyFormProps<T>): JSX.Element {
    const [parameters, setParameters] = useState<T>(initialParameters);

    useEffect(() => {
        setParameters(initialParameters);
    }, [initialParameters]);

    useEffect(() => {
        const isValid = validate(parameters);
        onValidation(isValid);
    }, [parameters, validate, onValidation]);

    const handleFieldUpdate = <K extends keyof T>(field: K, value: T[K]) => {
        setParameters((prevState) => {
            const updatedState = { ...prevState, [field]: value };
            if (!isEqual(prevState, updatedState)) {
                onUpdate(updatedState);
                return updatedState;
            }
            return prevState;
        });
    };

    return (
        <div>
            {children({
                parameters,
                handleFieldUpdate,
                isLoading
            })}
        </div>
    );
}
