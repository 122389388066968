import { ResinTypeData } from "@services/space/resinTypes/models";

export const initialResinDataMap: { [key: string]: ResinTypeData } = {
    PE: {
        name: "PE",
        abbreviation: "PE",
        referenceMeltDensity: 0.86379,
        meltDensityDelta: 0.0005126,
        plateauModulus: 200000
    },
    PP: {
        name: "PP",
        abbreviation: "PP",
        referenceMeltDensity: 0.9,
        meltDensityDelta: 0.0006,
        plateauModulus: 180000
    },
    PS: {
        name: "PS",
        abbreviation: "PS",
        referenceMeltDensity: 0.95,
        meltDensityDelta: 0.0007,
        plateauModulus: 160000
    },
    OTH: {
        name: "Other",
        abbreviation: "OTH",
        referenceMeltDensity: 0,
        meltDensityDelta: 0,
        plateauModulus: 0
    }
};
