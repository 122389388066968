import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faCircleSmall
} from "@fortawesome/pro-solid-svg-icons";
import { Button } from "@cpchem/covalence-ui";
import "./styles.scss";

export type SpaceCarouselProps = {
    className?: string;
    children: React.ReactNode[];
    label: string;
    testId?: string;
    initialActiveItemIndex: number;
    onActiveItemIndexChange: (idx: number) => void;
};

export function Carousel({
    className,
    children,
    label,
    testId,
    initialActiveItemIndex,
    onActiveItemIndexChange
}: SpaceCarouselProps): JSX.Element {
    const cnParts = ["space-carousel"];

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    useEffect(() => {
        if (initialActiveItemIndex !== undefined) {
            setActiveItemIndex(initialActiveItemIndex);
        }
    }, [initialActiveItemIndex]);

    if (className) {
        cnParts.push(className);
    }

    const handlePrevButtonClick = () => {
        setActiveItemIndex(activeItemIndex - 1);
        onActiveItemIndexChange(activeItemIndex - 1);
    };

    const handleNextButtonClick = () => {
        setActiveItemIndex(activeItemIndex + 1);
        onActiveItemIndexChange(activeItemIndex + 1);
    };

    const handleBulletClick = (idx: number) => {
        setActiveItemIndex(idx);
        onActiveItemIndexChange(idx);
    };

    const getBulletIcons = () => {
        return children.map((child, index) => {
            const uniqueKey =
                React.isValidElement(child) && child.props.id
                    ? child.props.id
                    : `bullet-${index}`;
            return (
                <Button
                    key={uniqueKey}
                    icon={<FontAwesomeIcon icon={faCircleSmall} />}
                    onClick={() => handleBulletClick(index)}
                    className="bullet"
                    isDisabled={activeItemIndex === index}
                    testId={`${testId}-carousel-bullet-${index + 1}`}
                />
            );
        });
    };

    const cn = cnParts.join(" ");
    return (
        <div className={cn} data-testid={`${testId}-carousel`}>
            <div className="label">{label}</div>
            <div className="content">
                <Button
                    onClick={handlePrevButtonClick}
                    isDisabled={activeItemIndex === 0}
                    icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    className="chevron-button"
                    text={""}
                    testId={`${testId}-carousel-prev-button`}
                />
                <span>{children[activeItemIndex]}</span>
                <Button
                    onClick={handleNextButtonClick}
                    isDisabled={activeItemIndex === children.length - 1}
                    icon={<FontAwesomeIcon icon={faChevronRight} />}
                    className="chevron-button"
                    text={""}
                    testId={`${testId}-carousel-next-button`}
                />
            </div>
            <div className="bullet-list">{getBulletIcons()}</div>
        </div>
    );
}
