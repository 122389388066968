import React from "react";
import { Button, Modal, ModalContent } from "@cpchem/covalence-ui";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface DeleteDatasetsGroupModalProps {
    className?: string;
    isOpen: boolean;
    isDeletingAllGroups: boolean;
    /**
     * Required if isDeletingAll is false
     */
    groupName?: string;
    /**
     * Required if isDeletingAll is false
     */
    totalDatasets?: number;
    /**
     * Required if isDeletingAll is true
     */
    totalGroups?: number;
    onDeleteGroups?: () => void;
    onDeleteGroup?: (groupName: string) => void;
    onRequestCancel?: () => void;
    onRequestClose: () => void;
    testId?: string;
}

export function DeleteDatasetsGroupModal({
    className,
    isOpen,
    isDeletingAllGroups,
    groupName,
    totalDatasets,
    totalGroups,
    onDeleteGroups,
    onDeleteGroup,
    onRequestCancel,
    onRequestClose,
    testId
}: DeleteDatasetsGroupModalProps): JSX.Element {
    const cnParts = ["remove-grouped-datasets-modal", "space-modal"];

    if (className) {
        cnParts.push(className);
    }

    function handleDeleteGroups() {
        onDeleteGroups?.();
        onRequestClose();
    }

    function handleDeleteGroup() {
        if (groupName && onDeleteGroup) {
            onDeleteGroup(groupName);
        }
        onRequestClose();
    }

    const modalTitle = isDeletingAllGroups
        ? "Delete All Datasets"
        : `Delete "${groupName}"`;
    const modalContentTitle = isDeletingAllGroups
        ? "Are you sure you want to delete all groups?"
        : `Are you sure you want to delete this group?`;
    const modalContentText = isDeletingAllGroups
        ? `You are going to delete ${totalGroups} groups.`
        : `This group contains ${totalDatasets} datasets.`;

    const removeButtonTestId = testId ? `${testId}-delete` : undefined;
    const cancelButtonTestId = testId ? `${testId}-cancel` : undefined;
    const closeButtonTestId = testId ? `${testId}-close` : undefined;

    const cn = cnParts.join(" ");
    return (
        <Modal
            className={cn}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            showCloseButton={false}
            testId={testId}
        >
            <div className="modal-header">
                <div className="modal-header-title">{modalTitle}</div>
                <Button
                    className="modal-close-button"
                    onClick={onRequestClose}
                    variant="text"
                    icon={<FontAwesomeIcon icon={faX as IconProp} />}
                    size="large"
                    testId={closeButtonTestId}
                />
            </div>
            <ModalContent>
                <div className="remove-datasets-modal-content">
                    <div className="modal-content-title">
                        {modalContentTitle}
                    </div>
                    <div className="modal-content-text">{modalContentText}</div>
                </div>

                <div className="modal-action-buttons">
                    <Button
                        color="primary"
                        variant="outline"
                        text="Cancel"
                        onClick={
                            isDeletingAllGroups
                                ? onRequestClose
                                : onRequestCancel
                        }
                        testId={cancelButtonTestId}
                    />
                    <Button
                        className="remove-dataset"
                        color="danger"
                        variant="solid"
                        onClick={
                            isDeletingAllGroups
                                ? handleDeleteGroups
                                : handleDeleteGroup
                        }
                        testId={removeButtonTestId}
                        text="Delete"
                    />
                </div>
            </ModalContent>
        </Modal>
    );
}
