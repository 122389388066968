import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader
} from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faTimes,
    faSquareDashedCirclePlus
} from "@fortawesome/pro-solid-svg-icons";
import { DatasetsSearchBar } from "./components/datasets-search-bar";
import {
    StoredDataset,
    StoredDatasetGroup
} from "@services/space/datasets/models";
import { CreateGroupModal } from "../create-group-modal/create-group-modal";
import { AddToGroupMenu } from "./components/add-to-group-menu";
import { DatasetsDataGrid } from "./components/datasets-selector-grid";
import "./modal-styles.scss";
import { useUserDatasets } from "../../../hooks/dataset-hooks";

export interface DatasetsSelectorModalProps {
    className?: string;
    isOpen: boolean;
    isLoading?: boolean;
    isGroupedDatasets?: boolean;
    selectedDatasets?: StoredDataset[];
    selectedDatasetGroups?: StoredDatasetGroup[];
    onClose: () => void;
    onSelectDatasets: (datasets: StoredDataset[], groupNames: string[]) => void;
    onCreateGroup: (newGroupName: string) => void;
    testId?: string;
}

export function DatasetsSelectorModal({
    className,
    isOpen,
    isLoading,
    isGroupedDatasets,
    selectedDatasetGroups,
    selectedDatasets,
    onClose,
    onSelectDatasets,
    onCreateGroup,
    testId
}: DatasetsSelectorModalProps): JSX.Element {
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
    const userUploadedDatasets = useUserDatasets();
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

    const existingGroupNames =
        selectedDatasetGroups?.map((group) => group.name) || [];

    const filteredDatasets = isGroupedDatasets
        ? userUploadedDatasets
        : userUploadedDatasets.filter(
              (dataset: StoredDataset) =>
                  !(selectedDatasets ?? []).some(
                      (selected) => selected.fileName === dataset.fileName
                  )
          );

    const allFilesSelected =
        selectedFiles.length === filteredDatasets.length &&
        filteredDatasets.length !== 0;

    const handleSelectAllFiles = () => {
        if (allFilesSelected) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(filteredDatasets.map((file) => file.fileName));
        }
    };

    const handleSelectFile = (fileName: string, isSelected: boolean) => {
        setSelectedFiles((prevSelectedFiles) => {
            if (isSelected) {
                return [...prevSelectedFiles, fileName];
            }
            return prevSelectedFiles.filter((file) => file !== fileName);
        });
    };

    const handleOnBack = () => {
        setSelectedFiles([]);
        onClose();
    };

    const handleCreateGroup = (newGroupName: string) => {
        onCreateGroup(newGroupName);
        setIsCreateGroupModalOpen(false);
    };

    const handleGroupSelection = (groupName: string, isSelected: boolean) => {
        setSelectedGroups((prevSelectedGroups) => {
            if (isSelected) {
                return [...prevSelectedGroups, groupName];
            }
            return prevSelectedGroups.filter((group) => group !== groupName);
        });
    };

    const handleSelectAllGroups = (isSelected: boolean) => {
        if (isSelected) {
            setSelectedGroups(existingGroupNames);
        } else {
            setSelectedGroups([]);
        }
    };

    const isAddButtonDisabled = isGroupedDatasets
        ? selectedGroups.length === 0 || selectedFiles.length === 0
        : selectedFiles.length === 0;

    const cn = ["datasets-selector-modal", className].filter(Boolean).join(" ");

    const dataGridTestId = testId ? `${testId}-data-grid` : undefined;
    const dataGridRowsTestId = testId ? `${testId}-data-grid-row` : undefined;
    const closeButtonTestId = testId ? `${testId}-close` : undefined;
    const searchBarTestId = testId ? `${testId}-search-bar` : undefined;
    const backButtonTestId = testId ? `${testId}-back` : undefined;
    const addButtonTestId = testId ? `${testId}-add` : undefined;
    const addToGroupMenuTestId = testId
        ? `${testId}-add-to-group-menu`
        : undefined;

    const onConfirm = () => {
        const datasetsToAdd = selectedFiles
            .map((fileName) =>
                userUploadedDatasets.find((d) => d.fileName === fileName)
            )
            .filter((dataset): dataset is StoredDataset => !!dataset);

        onSelectDatasets(datasetsToAdd, selectedGroups);
        setSelectedFiles([]);
        setSelectedGroups([]);
        onClose();
    };

    return (
        <>
            <Modal
                className={cn}
                isOpen={isOpen}
                onRequestClose={onClose}
                showCloseButton={false}
                testId={testId}
            >
                <ModalHeader className="datasets-selector-modal-header">
                    <h2 className="datasets-selector-modal-title">
                        Dataset Selection
                    </h2>
                    <Button
                        className="close-button"
                        variant="text"
                        size="large"
                        icon={<FontAwesomeIcon icon={faTimes as IconProp} />}
                        onClick={onClose}
                        testId={closeButtonTestId}
                    />
                </ModalHeader>
                <ModalContent className="datasets-selector-modal-content">
                    <div className="modal-content-action-header">
                        <DatasetsSearchBar
                            className="datasets-search-bar"
                            datasets={filteredDatasets as StoredDataset[]}
                            onSelectDataset={(datasetName: string) => {
                                const dataset = userUploadedDatasets?.find(
                                    (d: StoredDataset) =>
                                        d.fileName === datasetName
                                );
                                if (dataset) {
                                    const isSelected = selectedFiles.includes(
                                        dataset.fileName
                                    );
                                    handleSelectFile(
                                        dataset.fileName,
                                        !isSelected
                                    );
                                }
                            }}
                            testId={searchBarTestId}
                            isLoading={isLoading}
                        />
                        {isGroupedDatasets && (
                            <Button
                                text="Create Group"
                                className="create-group-button"
                                color="primary"
                                variant="text"
                                isIconAfterText
                                icon={
                                    <FontAwesomeIcon
                                        icon={
                                            faSquareDashedCirclePlus as IconProp
                                        }
                                    />
                                }
                                onClick={() => setIsCreateGroupModalOpen(true)}
                            />
                        )}
                    </div>
                    <DatasetsDataGrid
                        filteredDatasets={filteredDatasets}
                        selectedFiles={selectedFiles}
                        onSelectFile={handleSelectFile}
                        onSelectAllFiles={handleSelectAllFiles}
                        allFilesSelected={allFilesSelected}
                        dataGridTestId={dataGridTestId}
                        dataGridRowsTestId={dataGridRowsTestId}
                    />
                </ModalContent>
                <ModalFooter className="datasets-selector-modal-footer">
                    <Button
                        className="back-button"
                        color="primary"
                        onClick={handleOnBack}
                        size="large"
                        text="Back"
                        variant="outline"
                        testId={backButtonTestId}
                    />
                    <div className="modal-footer-action-buttons">
                        {isGroupedDatasets && (
                            <AddToGroupMenu
                                existingGroupNames={existingGroupNames}
                                selectedGroups={selectedGroups}
                                onGroupSelectionChange={handleGroupSelection}
                                onSelectAllGroups={handleSelectAllGroups}
                                isDisabled={selectedDatasetGroups?.length === 0}
                                testId={addToGroupMenuTestId}
                            />
                        )}
                        <Button
                            className="add-datasets-button"
                            isDisabled={isAddButtonDisabled}
                            color="primary"
                            onClick={onConfirm}
                            size="large"
                            text={isGroupedDatasets ? "Confirm" : "Add"}
                            variant="solid"
                            testId={addButtonTestId}
                        />
                    </div>
                </ModalFooter>
            </Modal>
            <CreateGroupModal
                isOpen={isCreateGroupModalOpen}
                existingGroupNames={existingGroupNames}
                onCreateGroup={handleCreateGroup}
                onRequestClose={() => setIsCreateGroupModalOpen(false)}
            />
        </>
    );
}
