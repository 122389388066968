export type SummaryAttributes =
    | "sample_name"
    | "sample_filename"
    | "sample_temperature"
    | "mi"
    | "hlmi"
    | "flow_activation_energy"
    | "yield_stress"
    | "cy_eta_0"
    | "cy_eta_0_error"
    | "cy_tau_eta"
    | "cy_tau_eta_error"
    | "cy_a"
    | "cy_a_error"
    | "cy_n"
    | "cy_n_error"
    | "cy_crossover_modulus"
    | "cy_crossover_freq"
    | "cy_low_freq_viscosity"
    | "cy_low_freq_loss_tangent"
    | "cy_high_freq_viscosity"
    | "cy_high_freq_loss_tangent"
    | "cy_mi_stress_viscosity"
    | "cy_mi_stress_freq"
    | "cy_hlmi_stress_viscosity"
    | "cy_hlmi_stress_freq"
    | "cy_high_freq_n"
    | "hn_crossover_freq"
    | "hn_crossover_modulus"
    | "hn_eta_0"
    | "hn_tau_eta"
    | "hn_a"
    | "hn_n"
    | "hn_low_freq_g1"
    | "hn_low_freq_g2"
    | "hn_low_freq_laun_recoverable_shear"
    | "hn_high_freq_laun_recoverable_shear";

export const defaultSelectedSummaryAttributes: SummaryAttributes[] = [
    "sample_name",
    "sample_filename",
    "sample_temperature",
    "mi",
    "hlmi",
    "flow_activation_energy",
    "cy_eta_0",
    "cy_tau_eta",
    "cy_a",
    "cy_n",
    "cy_low_freq_viscosity",
    "cy_low_freq_loss_tangent",
    "cy_high_freq_viscosity",
    "cy_high_freq_loss_tangent",
    "cy_mi_stress_viscosity",
    "cy_mi_stress_freq",
    "cy_hlmi_stress_viscosity",
    "cy_hlmi_stress_freq",
    "hn_crossover_freq",
    "hn_crossover_modulus"
];
